import React from "react";

export default function SleepApnea(props) {
  if (props.language === "esp") {
    return (
      <section className="promo-content-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-12">
              <div className="promo-text">
                <h3>Apnea del Sueño </h3>
                <p>
                  Si tiene trastornos del sueño, cansancio durante el día y / o
                  ronquidos fuertes por la noche, es posible que tenga apnea del
                  sueño. Si no se trata, puede provocar problemas de salud
                  graves. Ven a nuestra clínica y deja que nuestros médicos lo
                  traten.
                </p>
                <h3>Solicitud de Ayuda para Dormir</h3>
                <p>
                  Ofrecemos dispositivos de ayuda para dormir anti-ronquidos de
                  alta calidad que son asequibles, flexibles y cómodos para los
                  pacientes.
                </p>
                <br />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
              <img
                className="rounded"
                src={require("../../../assets/img/silentnite.png")}
                alt="Cavities"
              />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="promo-content-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-12">
              <div className="promo-text">
                <h3>Sleep Apnea </h3>
                <p>
                  Sleep apnea is a potentially serious sleep disorder in which
                  breathing repeatedly stops and starts. If you snore loudly and
                  feel tired even after a full night's sleep, you might have
                  sleep apnea.
                </p>
                <h4>Sleep Apnea Application</h4>
                <p>
                  We offer high quality anti-snoring and sleep apnea devices
                  that is affordable flexible and comfortable for patients.
                </p>
                <br />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
              <img
                className="rounded"
                src={require("../../../assets/img/silentnite.png")}
                alt="Cavities"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
