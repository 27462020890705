import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import "./assets/style.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./assets/css/aos.css";
import "./assets/css/slick.css";
import "./assets/css/default.css";
import "./assets/css/animate.css";
import "./assets/css/style-two.css";
import "./assets/css/responsive.css";
import "./assets/css/slick-theme.css";
import "./assets/css/fontawesome.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

Sentry.init({
  dsn: "https://b6656cbb89234767b9c798c708300a6b@o975348.ingest.sentry.io/5931338",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
