import React, { Component } from "react";
import SectionTitleOne from "../SectionTitleOne";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import GalleryData from "../Data/GalleryData";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function GalleryOne(props) {
  var img1 = require("../../assets/img/gallery/dentalroom.jpg");
  var img2 = require("../../assets/img/gallery/dentalroom02.jpg");
  var img3 = require("../../assets/img/gallery/lobby.jpg");
  var img4 = require("../../assets/img/gallery/g4.jpg");
  var img5 = require("../../assets/img/gallery/g5.jpg");
  var img6 = require("../../assets/img/gallery/dentalroom.jpg");

  return (
    <section className="gallery-one-wrapper section-bg text-white section-padding">
      <div className="container">
        <SectionTitleOne
          BigTitle={
            props.language === "esp" ? "Nuestra Galleria" : "Our Gallery"
          }
        />
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            <Carousel>
              <Carousel.Item interval={1000}>
                <div className="single-gallery-item d-flex align-items-center">
                  <img src={img1} alt="office" />
                </div>
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <div className="single-gallery-item d-flex align-items-center">
                  <img src={img2} alt="office" />
                </div>
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <div className="single-gallery-item d-flex align-items-center">
                  <img src={img3} alt="office" />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="row justify-content-center mb-n5">
          <div>
            <Link style={{ color: "white" }} to="/PhotoGallery">
              <button className="submit-btn">
                {props.language === "esp" ? "¡Ver más!" : "See more!"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
