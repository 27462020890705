import React, { Component } from "react";
import SectionTitleOne from "../SectionTitleOne";
import ServiceItem from "./ServiceItem";

class ServiceOne extends Component {
  render() {
    let ServiceData = this.props.ServiceData;
    let readLabel = this.props.language === "esp" ? "lee mas" : "read more";

    return (
      <section className="services-wrapper section-bg section-padding text-white">
        <div className="container">
          <SectionTitleOne
            BigTitle={
              this.props.language === "esp" ? "Tratamientos" : "Treatments"
            }
          />

          <div className="row">
            {ServiceData.map((item) => {
              return (
                <ServiceItem
                  key={item.id}
                  ID={item.id}
                  Title={item.title}
                  ImageURL={item.imgUrl}
                  Text={item.text}
                  ReadLabel={readLabel}
                />
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default ServiceOne;
