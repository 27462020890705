import React from "react";

export default function Crowns(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Coronas</h2>
                  <p>
                    Se necesita una corona cuando un diente se sometió a un
                    tratamiento de conducto. Después del tratamiento de
                    conducto, el diente se vuelve quebradizo y se rompe al
                    morder. También se necesita una corona cuando el diente está
                    significativamente roto, fracturado o para reemplazar una
                    corona vieja que se ha deteriorado.
                  </p>
                  <br /> <h4>Tipos de coronas dentales:</h4>
                  <ul>
                    <li>Coronas enteramente de cerámica</li>
                    <li>Coronas de zirconio</li>
                    <li>Porcelana fusionada con coronas de metal</li>
                    <li>Coronas de oro</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalcrown.png")}
                  alt="Dental Crown"
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded mt-5"
                  src={require("../../../assets/img/femalePatient.png")}
                  alt="Female Patient"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Carillas</h2>
                  <p>
                    Las carillas son una opción para corregir espacios,
                    decoloraciones, dientes torcidos, rotos, astillados o más
                    pequeños que el promedio. Las carillas son conchas delgadas
                    del color de los dientes que se adhieren permanentemente a
                    la superficie frontal de los dientes para mejorar su
                    apariencia. Por lo general, están hechos de porcelana o
                    material compuesto de resina.
                  </p>
                  <br /> <h3>Puentes</h3>
                  <p>
                    Los puentes son otra alternativa común a las dentaduras
                    postizas e implantes, para reemplazar un diente faltante.
                    Los puentes están formados por coronas que se mantienen en
                    su lugar mediante una corona dental cementada a los dientes
                    vecinos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Crowns</h2>
                  <p>
                    A crown is needed when a tooth had a root canal treatment
                    done. After root canal treatment, the tooth becomes brittle
                    and will break under biting pressure. A crown is also needed
                    when the tooth is significantly broken, fractured, and/or to
                    replace an old crown that has decayed.
                  </p>
                  <br /> <h4>Types of Dental Crowns</h4>
                  <ul>
                    <li>All ceramic crowns</li>
                    <li>Zirconia crowns</li>
                    <li>Porcelain fused to metal crowns</li>
                    <li>Gold crowns</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalcrown.png")}
                  alt="Dental Crown"
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded mt-5"
                  src={require("../../../assets/img/femalePatient.png")}
                  alt="Female Patient"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Veneers</h2>
                  <p>
                    Veneers are an option to correct gaps, discolorations,
                    crooked, broken, chipped or smaller-than-average teeth.
                    Veneers are thin, teeth colored shells that are permanently
                    bonded to the front surface of teeth to improve their
                    appearance. They are usually made from porcelain or
                    resin-composite material.
                  </p>
                  <br /> <h3>Bridges</h3>
                  <p>
                    Bridges are another common alternative to dentures and
                    implants, to replace missing teeth. Bridges are made up of
                    crowns that are held in place by a dental crown that is
                    cemented to neighboring teeth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
