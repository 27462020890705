import React, { Component } from "react";
import Appointment from "../Appointment";
import Banner from "../Banner";

class Error404 extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container page-banner">
          <h1>404 Error</h1>
          <p style={{ fontWeight: 400 }}>
            The page you are looking for could not be found. Please try again or
            contact the clinic.
          </p>
        </div>
        <Appointment title={"Contact Us"} />
      </React.Fragment>
    );
  }
}

export default Error404;
