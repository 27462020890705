import React from "react";
import { Link } from "react-router-dom";

export default function ServiceItem(props) {
  return (
    <div className="col-md-6 col-12 col-lg-6 col-xl-4" key={props.ID}>
      <div className="single-service-box text-center">
        <div className="service-icon">
          <img
            src={require("../../assets/img/icons/" + props.ImageURL)}
            alt=""
          />
        </div>
        <h3>{props.Title}</h3>
        <p className="text-left">{props.Text}</p>
        <div className="text-left">
          <Link className="btn-link" to={`/ServiceDetails/${props.ID}`}>
            {props.ReadLabel}
          </Link>
        </div>
      </div>
    </div>
  );
}
