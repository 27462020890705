import React from "react";

export default function Whitening(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h1>Nuestro Sistema de Blanqueamiento</h1>
                  <p>
                    Los dientes manchados son causados por beber café, té,
                    refrescos, vino tinto, tabaco de mascar, y fumar. Nuestra
                    clínica ofrece tres tipos de sistemas de blanqueamiento.
                    Nuestro sistema de blanqueamiento es rápido, fácil, y
                    eficaz.
                  </p>
                  <div className="my-5">
                    <h3>Blanqueamiento en el Consultorio</h3>
                    <p>
                      Si tiene una hora libre, ¡podemos mejorar su sonrisa!
                      <br /> El blanqueamiento en el consultorio implica una
                      cita de 1,5 horas, donde bloquearemos sus encías para
                      protegerlas, luego aplicaremos nuestro potente y eficaz
                      material blanqueador. Después del tratamiento de
                      blanqueamiento, los pacientes notarán una diferencia en el
                      color de sus dientes de inmediato.
                    </p>
                    <br /> <h3>Kit Personalizado Para Llevar a Casa</h3>
                    <p>
                      Ofrecemos un kit para llevar a casa para aquellos que
                      están demasiado ocupados como para tener tiempo para estar
                      con nosotros en la oficina. Nuestro sistema de
                      blanqueamiento personalizado para llevar a casa le
                      brindará una gran experiencia de blanqueamiento en la
                      comodidad de su hogar. Después de un ajuste rápido en la
                      oficina para sus bandejas personalizadas, lo enviarán a
                      casa con ocho jeringas de gel blanqueador. De esa manera,
                      puede blanquear a su conveniencia.
                    </p>
                    <br /> <h3>Kit de Prueba Para Llevar a Casa</h3>
                    <p>
                      ¿Eres nuevo en la realización de blanqueamiento? Pruebe
                      nuestros kits versátiles de blanqueamiento para llevar a
                      casa. Proporcionará mejores resultados que un kit comprado
                      en una farmacia y es muy asequible. Su fuerza profesional
                      hará sus dientes blancos y brillantes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalwhitening.jpg")}
                  alt="Dental Whitening"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h1>Our Whitening System</h1>
                  <p>
                    Stained teeth are caused by drinking coffee, tea, soft
                    drinks, red wine, chewing tobacco and smoking. Our clinic
                    offer three types of whitening systems. Our whitening system
                    is fast, easy, and efficient.
                  </p>
                  <div className="my-5">
                    <h3>In-Office Whitening</h3>
                    <p>
                      If you have an hour to spare, we can improve your smile!
                      <br /> The in-office whitening involves a 1.5 hours
                      appointment, where first we will block your gums to
                      protect it, then apply our powerful and effective
                      whitening material. After the whitening treatment,
                      patients will see a difference in their teeth color right
                      away!
                    </p>
                    <br /> <h3>Custom Take Home Kit</h3>
                    <p>
                      We offer a take home kit for the busy body who does not
                      have the time to be with us in the office. Our custom take
                      home whitening system will give you a great whitening
                      experience in the comfort of your own home. After a quick
                      in office fitting for your custom trays, you will be sent
                      home with eight syringes of bleaching gel. You are able to
                      bleach at your convenience.
                    </p>
                    <br /> <h3>Take Home Trials Kit</h3>
                    <p>
                      New to Bleaching? Try our one-size take home whitening
                      kits. It will provides a better results then a drug store
                      bought kit and it is very affordable. Its professional
                      strength will make your teeth bright and white.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalwhitening.jpg")}
                  alt="Dental Whitening"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
