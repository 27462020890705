import React from "react";

export default function EmergencyDentistry(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="serviceText">
                  <h2>Diente astillado?</h2>
                  <p>
                    Si el diente no duele, se puede rellenar y parchear. Si le
                    duele, puede ser necesario un tratamiento de conducto o una
                    corona.
                  </p>
                  <h2>El relleno se salió?</h2>
                  <p>
                    Si el diente no duele, podemos reemplazar el relleno con
                    otro relleno; de lo contrario, un tratamiento de conducto y
                    una corona son apropiados.
                  </p>
                  <h2>Dolor de diente?</h2>
                  <p>
                    Si el diente se puede salvar, el dentista intentará salvarlo
                    haciendo un tratamiento de conducto y una corona. Si el
                    diente no se puede salvar, será necesaria una extracción.
                  </p>
                  <h2>Hinchazón?</h2>
                  <p>
                    Si la hinchazón no es tan grave, se ofrecerá una incisión y
                    drenaje. Si la hinchazón es demasiado severa, el dentista
                    recomendará al paciente que visite la sala de emergencias
                    local.
                  </p>
                  <h2>La corona se cayó?</h2>
                  <p>
                    Si el diente coronado se puede salvar y está en buena forma,
                    el dentista volverá a colocar la corona. Si el diente
                    coronado está en mal estado y no se puede salvar, será
                    necesaria una extracción o un tratamiento de conducto y una
                    nueva corona.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalemergency.png")}
                  alt="Dental Emergency"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="serviceText">
                  <h2>Chipped tooth?</h2>
                  <p>
                    If the tooth is not hurting, then the tooth can be fill and
                    patch. If it hurts, then a root canal or crown might be
                    necessary.
                  </p>
                  <h2>Filling came out?</h2>
                  <p>
                    If the tooth is not hurting, then the tooth can be fill and
                    patch. If it hurts, then a root canal or crown might be
                    necessary.
                  </p>
                  <h2>Toothache?</h2>
                  <p>
                    If the tooth is savable, the dentist will try to save the
                    tooth by doing a root canal and crown. If the tooth is not
                    savable, then an extraction will be necessary.
                  </p>
                  <h2>Swelling?</h2>
                  <p>
                    If the swelling is not severe, an incision and drainage will
                    be the option. If the swelling is too severe, the dentist
                    will recommend the patient to visit the local emergency
                    room.
                  </p>
                  <h2>Crown fell off ?</h2>
                  <p>
                    If the crowned tooth is savable and in good shape, then the
                    dentist will recement the crown back. If the crowned tooth
                    is in bad shape and is unsavable, then an extraction or root
                    canal and new crown will be necessary.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalemergency.png")}
                  alt="Dental Emergency"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
