import React from "react";

export default function Cleaning(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Limpieza</h2>
                  <p>
                    Tener una limpieza regular ayudará a prevenir enfermedades
                    de las encías, pérdida de dientes, gingivitis, pérdida de
                    esmalte, periodontitis, y caries. Se ha descubierto que la
                    mala higiene bucal es la causa de enfermedades a largo plazo
                    como cáncer, enfermedades cardíacas, osteoporosis,
                    enfermedades respiratorias, y diabetes.
                  </p>
                  <p>
                    En nuestra clínica, las limpiezas dentales las realiza un
                    higienista dental registrado. Nuestros higienistas son
                    profesionales de la salud bucal con licencia que trabajan en
                    estrecha colaboración con el dentista general para brindar
                    atención dental preventiva.
                  </p>
                  <p>
                    Los tratamientos con flúor se ofrecen como protector de los
                    dientes para ayudar a combatir las caries después de la
                    limpieza dental. Nuestra oficina utiliza un barniz de
                    fluoruro de alta calidad para nuestro tratamiento de
                    fluoruro.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/dentalpatient.png")}
                  alt="dental patient"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="promo-content-section section-bg text-white promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mb-4 mb-lg-0">
                <img
                  src={require("../../../assets/img/dentalcleaning.png")}
                  alt="Dental Cleaning"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Limpieza Profunda</h2>
                  <p>
                    La limpieza profunda, también conocida como raspado de
                    dientes y alisado radicular, se considera el tratamiento
                    estándar de oro para la enfermedad periodontal crónica. Al
                    reducir las bolsas que se desarrollan entre los dientes y
                    las encías mediante el raspado de los dientes y el alisado
                    radicular, reducirá el riesgo de sufrir pérdida de dientes,
                    huesos y tejidos asociados con la enfermedad periodontal
                    crónica. <br /> <br /> La enfermedad periodontal crónica
                    ocurre cuando las bacterias de la placa hacen que las encías
                    se separen de los dientes. Esto hace que crezcan grandes
                    bolsas entre los dientes y las encías, y pueden crecer más
                    bacterias allí que no puede alcanzar con el cepillado de
                    dientes regular en casa.
                    <br /> <br /> El raspado de los dientes y el alisado
                    radicular ayudan a tratar la enfermedad periodontal crónica
                    conocida como enfermedad de las encías. Son más completos
                    que una limpieza dental típica. El raspado de los dientes y
                    el alisado radicular a menudo requieren más de una visita al
                    dentista y podrían requerir un anestésico local según la
                    gravedad de su enfermedad periodontal crónica y si tiene
                    retracción de las encías. La recuperación de este
                    procedimiento generalmente solo toma unos días, pero puede
                    tomar más.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-video-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>
                    Si no se trata, la enfermedad periodontal crónica puede
                    provocar:
                  </h2>
                  <ul>
                    <li>pérdida de hueso y tejido </li>
                    <li>pérdida de dientes</li> <li>dientes flojos</li>
                    <li>dientes movidos</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Cleaning</h2>
                  <p>
                    Having regular cleanings will help prevent gums diseases,
                    tooth loss, gingivitis, enamel loss, periodontitis, and
                    cavities. Poor oral hygiene has been found to be the causes
                    of long-term illnesses like cancer, heart diseases,
                    osteoporosis, respiratory diseases, and diabetes.
                  </p>
                  <p>
                    At our clinic, dental cleanings are professionally cleaned
                    by a registered dental hygienist. Our hygienists are
                    licensed oral health care professionals who work closely
                    with the general dentist to provide preventative dental
                    care.
                  </p>
                  <p>
                    Fluoride treatments are offered as a protectant for your
                    teeth to help fight against cavities after your dental
                    cleaning. Our office uses a high-quality fluoride varnish
                    for our fluoride treatment.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/dentalpatient.png")}
                  alt="dental patient"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="promo-content-section section-bg text-white promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mb-4 mb-lg-0">
                <img
                  src={require("../../../assets/img/dentalcleaning.png")}
                  alt="dental cleaning"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Deep Cleaning</h2>
                  <p>
                    Deep cleaning, also known as teeth scaling and root planing
                    are considered to be the gold standard treatment for chronic
                    periodontal disease. By reducing the pockets that develop
                    between your teeth and gums through teeth scaling and root
                    planing, you will reduce your risk of experiencing tooth,
                    bone, and tissue loss associated with chronic periodontal
                    disease. <br /> <br /> Chronic periodontal disease occurs
                    when the bacteria in plaque cause your gums to pull away
                    from your teeth. This causes large pockets to grow between
                    your teeth and gums, and more bacteria can grow there that
                    you cannot reach with regular teeth brushing at home. <br />
                    <br /> Teeth scaling and root planing help to treat chronic
                    periodontal disease known as gum disease.They are more
                    in-depth than a typical teeth cleaning. Teeth scaling and
                    root planing often take more than one dental visit and could
                    require a local anesthetic based on the severity of your
                    chronic periodontal disease and if you have receding gums.
                    Recovery from this procedure usually only takes a few days
                    but may take longer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-video-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>
                    If left untreated, chronic periodontal disease can lead to:
                  </h2>
                  <ul>
                    <li style={{ listStyle: "circle" }}>
                      bone and tissue loss
                    </li>
                    <li>tooth loss</li>
                    <li>loose teeth</li>
                    <li>moving teeth</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
