import * as ActionTypes from "./ActionTypes";

export const language = (state = { language: "eng" }, action) => {
  switch (action.type) {
    case ActionTypes.Change_Language:
      const language = action.payload;
      return { ...state, language };
    default:
      return state;
  }
};
