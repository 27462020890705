import React, { Component } from "react";
import Appointment from "../Appointment";
import SectionTitleOne from "../SectionTitleOne";
import ReactFancyBox from "react-fancybox";
import { withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { compose } from "redux";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import "react-fancybox/lib/fancybox.css";
import Banner from "../Banner";
import BeforeAfterData from "../Data/BeforeAfterData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

class PhotoGallery extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      language: cookies.get("language") || "eng",
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        language: language.language,
      };
    }

    return null;
  }
  render() {
    var img1 = require("../../assets/img/gallery/g1.jpg");
    var img2 = require("../../assets/img/gallery/g2.jpg");
    var img3 = require("../../assets/img/gallery/g3.jpg");
    var img4 = require("../../assets/img/gallery/g4.jpg");
    var img5 = require("../../assets/img/gallery/g5.jpg");
    return (
      <React.Fragment>
        <Banner
          pageTitle={
            this.state.language === "esp" ? "Nuestra Galeria" : "Our Gallery"
          }
          language={this.state.language}
        />
        <div className="container mt-5">
          <SectionTitleOne
            BigTitle={
              this.state.language === "esp"
                ? "Imágenes Antes y Después"
                : "Before/After Images"
            }
          />
          {BeforeAfterData.map((item, index) => {
            return (
              <div key={index} className="row">
                <div className="col-6 mx-auto">
                  <div className="single-gallery-item gallery-thumbnail">
                    <ReactFancyBox
                      thumbnail={require("../../assets/img/before_after/" +
                        item.before)}
                      image={require("../../assets/img/before_after/" +
                        item.before)}
                    />
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="single-gallery-item gallery-thumbnail">
                    <ReactFancyBox
                      thumbnail={require("../../assets/img/before_after/" +
                        item.after)}
                      image={require("../../assets/img/before_after/" +
                        item.after)}
                    />
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>
        <Appointment
          title={
            this.state.language === "esp"
              ? "Solicitar Cita"
              : "Request Appointment"
          }
          language={this.state.language}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withCookies,
  withRouter
)(connect(mapStateToProps)(PhotoGallery));
