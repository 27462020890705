import React from "react";

export default function Promo(props) {
  return (
    <section className="appointment-promo-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6  col-12">
            <div className="appointment-promo-content">
              <h1>
                {props.language === "esp"
                  ? "¡Solicite su cita y comience su cambio de imagen de sonrisa!"
                  : "Request your appointment and start your smile makeover!"}
              </h1>
              <a href="#appointment" className="theme-btn">
                {props.language === "esp"
                  ? "Solicitar Cita"
                  : "Request Appointment"}
              </a>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-12">
            <div className="promo-right-banner text-center">
              <img src={require("../assets/img/doctor-nars.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
