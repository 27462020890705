import React from "react";

export default function Implants(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h1>Nuestras Opciones de Implantes</h1>
                  <p>
                    Los implantes están hechos de material metálico
                    biocompatible. Los implantes dentales son fuertes,
                    duraderos, y sirven como una gran base para su hermosa
                    sonrisa. Los implantes dentales también pueden prevenir la
                    pérdida futura de hueso en la mandíbula para una mejor salud
                    bucal.
                  </p>
                  <div className="my-5">
                    <h3>Implantes Individuales</h3>
                    <p>
                      Los implantes se utilizan para reemplazar un diente
                      faltante. Se coloca un implante dental para reemplazar la
                      parte de la raíz de un diente. Después de tres a seis
                      meses de curación, se coloca un pilar personalizado y la
                      corona en la parte superior. Este proceso le dará un
                      diente funcional y de aspecto muy realista.
                    </p>
                    <br /> <h3>All-On-X</h3>
                    <p>
                      ¿Dentaduras incómodas? Podemos ayudar a reemplazar todo el
                      arco superior o inferior de su boca con implantes. El
                      dentista puede colocar de cuatro a seis implantes y
                      colocar una prótesis permanente encima de los implantes.
                    </p>
                    <br /> <h3>Dentaduras Sobre Implantes</h3>
                    <p>
                      Las sobredentaduras de implantes también se denominan
                      “dentaduras postizas a presión” porque el mecanismo para
                      conectar la dentadura postiza a los implantes es un
                      conjunto a presión macho-hembra. Este “Ensamblaje a
                      presión” proporciona estabilidad, evita el movimiento de
                      adelante hacia atrás y de lado a lado, y la retención
                      evitando que la dentadura se aleje de los tejidos.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/femalePatient.png")}
                  alt="Female Patient"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h1>Our Implant Options</h1>
                  <p>
                    Implants are made of biocompatible metal material. Dental
                    implants are strong, long lasting, and serves as a great
                    foundation to your beautiful smile. Dental implants can also
                    prevent future bone loss in the jaw for a better oral
                    health.
                  </p>
                  <div className="my-5">
                    <h3>Single Implants</h3>
                    <p>
                      Implants are used to replace a missing tooth. A dental
                      implant is placed to replace the root-part of a tooth.
                      After three to six months healing time, a custom abutment
                      and crown is then placed on top. This process will give
                      you a highly realistic looking and functioning tooth.
                    </p>
                    <br /> <h3>All-On-X</h3>
                    <p>
                      Uncomfortable dentures? We can help to replace the whole
                      top or bottom arch of your mouth with implants. The
                      dentist can place four to six implants and seat a
                      permanent prosthesis on top of the implants.
                    </p>
                    <br /> <h3>Dentures Over Implants</h3>
                    <p>
                      Implant Overdentures are also called “Snap-On Dentures”
                      because the mechanism to connect the denture to the
                      implants is a male-female, snap assembly. This “Snap
                      Assembly” provides stability, prevents movement front to
                      back and side to side, and retention preventing the
                      denture from moving away from the tissues.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/femalePatient.png")}
                  alt="Female Patient"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
