import React from "react";
import { Link } from "react-router-dom";
import SectionTitleOne from "../SectionTitleOne";
import ServiceData from "../Data/ServiceData";
import ServiceItem from "./ServiceItem";
import getLanguageData from "../Helpers/getLanguageData";

export default function ServicePreview(props) {
  let serviceData = getLanguageData(
    props.language,
    ServiceData.serviceItems_Eng,
    ServiceData.serviceItems_Esp
  );
  serviceData = serviceData.slice(0, 6);
  let readLabel = props.language === "esp" ? "lee mas" : "read more";

  return (
    <section className="services-wrapper section-bg section-padding text-white">
      <div className="container">
        <SectionTitleOne
          BigTitle={props.language === "esp" ? "Tratamientos" : "Treatments"}
        />

        <div className="row">
          {serviceData.map((item) => {
            return (
              <ServiceItem
                key={item.id}
                ID={item.id}
                Title={item.title}
                ImageURL={item.imgUrl}
                Text={item.text}
                ReadLabel={readLabel}
              />
            );
          })}
        </div>
        <div
          className="row d-flex justify-content-end"
          style={{
            marginBottom: "-5rem",
            marginTop: "3rem",
            fontSize: "20px",
          }}
        >
          <Link style={{ color: "white" }} to="/Services">
            {props.language === "esp" ? "Ver más..." : "See more..."}
          </Link>
        </div>
      </div>
    </section>
  );
}
