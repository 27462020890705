import React from "react";

export default function HeroOne(props) {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ position: "relative" }}>
        <div
          className="hero-video"
        >
          <video
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
          >
            <source src={require("../../assets/video/DentalAccess.mp4")} />
          </video>
        </div>
        {props.language === "esp" ? (
          <h2
            className="overlayText"
          >
            Mejor Vida A Través De Una <b>Mejor Odontología</b>
          </h2>
        ) : (
          <h2
            className="overlayText"
          >
            Better Life Through <b>Better Dentistry</b>
          </h2>
        )}
      </div>
    </div>
  );
}
