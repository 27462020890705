import React from "react";

export default function Dentures(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        '
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h1>Nuestras Opciones de Dentaduras Postizas</h1>
                  <p>
                    Las dentaduras postizas que se ajustan correctamente tienen
                    numerosos beneficios, que incluyen mejorar el habla, la
                    capacidad de comer y la confianza en sí mismo. También puede
                    ayudar a evitar que su cara se caiga con el tiempo y puede
                    ofrecer protección a los dientes restantes. <br />
                    Hay numerosos tipos de falsos dientes disponibles,
                    incluyendo dentaduras parciales, dentaduras flexibles y
                    dentaduras completas. Ambos requieren un ajuste para que
                    coincidan con la forma, el tamaño y el color de sus dientes.
                  </p>
                  <div className="my-5">
                    <h3>Dentaduras Parciales</h3>
                    <p>
                      Las dentaduras postizas parciales se utilizan si aún le
                      quedan algunos dientes sanos. Estas dentaduras postizas a
                      menudo se recortan alrededor de los dientes sanos
                      restantes. Estos recortes pueden ser visibles cuando se
                      habla, pero se pueden hacer en el material del color del
                      diente.
                    </p>
                    <br /> <h3>Dentaduras Completas</h3>
                    <p>
                      Las dentaduras postizas completas se utilizan si a los
                      pacientes no les quedan dientes, lo que puede ocurrir
                      debido a una lesión, infección, periodontitis u otra
                      afección médica. Estos dientes artificiales están
                      adheridos a una placa que se asienta contra sus encías. El
                      material de las placas es de metal o acrílico y ambos
                      están hechos para combinar con sus encías. Se puede usar
                      adhesivo para ayudar a mantener la dentadura postiza en su
                      lugar si es necesario.
                    </p>
                    <br /> <h3>Dentaduras Postizas Flexibles</h3>
                    <p>
                      Las dentaduras postizas flexibles son una alternativa
                      popular a las dentaduras postizas tradicionales y están
                      hechas con materiales flexibles pero extremadamente
                      duraderos que pueden ser más cómodos de usar. Gracias a la
                      resina translúcida que combina con el color de su encía,
                      no requieren clips visibles como los que puede ver con las
                      dentaduras postizas parciales. Las dentaduras postizas
                      flexibles también son más livianas y menos voluminosas en
                      la boca. Pueden durar entre cinco y ocho años.
                    </p>
                    <p>
                      Las dentaduras postizas flexibles, parciales y completas
                      son removibles. Las dentaduras postizas removibles deben
                      sacarse todas las noches para que estén limpias. Se
                      recomienda a los pacientes con dentaduras postizas
                      flexibles, parciales o completas que eviten los alimentos
                      que son particularmente masticables, pegajosos o duros.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentaldentures02.png")}
                  alt="Dental Dentures"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="promo-content-section section-bg text-white promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mb-4 mb-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentaldentures.png")}
                  alt="Dental Dentures"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Efectos Secundarios de Dientes Falsos</h2>
                  <p>
                    Cuando obtiene la dentadura postiza por primera vez, puede
                    notar un aumento en la saliva. Esto es normal y desaparece a
                    medida que su boca se acostumbra a la nueva adición. Algunas
                    personas también experimentan náuseas temporales.
                  </p>
                  <br />
                  <p>
                    Es posible que tenga dificultad para comer al principio
                    mientras se acostumbra a su nueva dentadura postiza.
                    Comience con alimentos blandos y líquidos para acostumbrarse
                    a ellos. De manera similar, es posible que tenga
                    dificultades para hablar como solía hacerlo antes de
                    colocarse una dentadura postiza. Esto se vuelve más fácil a
                    medida que se acostumbra a su dentadura postiza. Practica
                    decir palabras difíciles de pronunciar para acelerar el
                    proceso.
                  </p>
                  <br />
                  <p>
                    Las dentaduras postizas a veces pueden causar irritación o
                    llagas en la boca mientras te acostumbras. Esto es común y,
                    a menudo, desaparece a medida que se adapta. Haga gárgaras
                    con agua salada y mantenga una excelente higiene bucal para
                    ayudar a tratar estos síntomas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-video-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>
                    Acuda a su clínica dental para ajustes de su dentadura
                    postiza si experimentó:
                  </h2>
                  <ul>
                    <li>
                      Dificultad para masticar después del período de adaptación
                      (que puede tardar aproximadamente una semana)
                    </li>
                    <li>
                      Cambio de ajuste con el tiempo, donde no es tan ceñido
                      como solía ser (las dentaduras postizas removibles pueden
                      resbalarse o caerse con más frecuencia, lo cual es normal
                      después de varios años pero requiere un reajuste)
                    </li>
                    <li>
                      Úlceras por presión donde encajan las dentaduras postizas,
                      especialmente después del período de ajuste
                    </li>
                    <li>
                      cambios constantes en el patrón del habla que no
                      desaparecen después del período de ajuste
                    </li>
                    <li>Olor que viene de los dientes falsos</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h1>Our Denture Options</h1>
                  <p>
                    Properly fitted dentures have numerous benefits, including
                    improving speech, eating capabilities, and your
                    self-confidence. It can also help to prevent your face from
                    sagging over time and may offer protection to your remaining
                    teeth. <br /> There are numerous types of false teeth
                    available, including partial dentures, flexible dentures,
                    and full dentures. Both require fitting so that they match
                    your teeth’s shape, size, and color.
                  </p>
                  <div className="my-5">
                    <h3>Partial Dentures</h3>
                    <p>
                      Partial dentures are used if you still have some healthy
                      teeth left. These dentures are often clipped around the
                      remaining healthy teeth. These clips may be visible when
                      you talk but can be made with tooth-colored material to be
                      discreet.
                    </p>
                    <br /> <h3>Full Dentures</h3>
                    <p>
                      Full dentures are used if patients have no teeth left,
                      which may occur due to injury, infection, periodontitis,
                      or other medical conditions. These artificial teeth are
                      attached to a plate that sits against your gums. The
                      plate’s material is either metal or an acrylic and both
                      are made to match your gums. Adhesive can be used to help
                      keep your dentures in place if necessary.
                    </p>
                    <br /> <h3>Flexible dentures</h3>
                    <p>
                      Flexible dentures are a popular alternative to traditional
                      dentures, and they are made with flexible but extremely
                      durable materials that can be more comfortable to wear.
                      Thanks to the translucent resin that matches your gum
                      color, they do not require any visible clips like those
                      you might see with partial dentures. Flexible dentures are
                      also more light weight and less bulky in the mouth. They
                      can last around five to eight years.
                    </p>
                    <p>
                      Flexible, partial, and full dentures are removable. They
                      must be taken out every night to be cleaned. It is
                      recommended to avoid foods that are particularly chewy,
                      sticky, or hard.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentaldentures02.png")}
                  alt="Dental Dentures"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="promo-content-section section-bg text-white promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mb-4 mb-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentaldentures.png")}
                  alt="Dental Dentures"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Side Effects of False Teeth</h2>
                  <p>
                    When you first get false teeth, you may notice an increase
                    in saliva. This is normal and will subside as your mouth
                    gets used to the new addition. Some people also experience
                    temporary nausea.
                  </p>
                  <br />
                  <p>
                    You may have difficulty eating at first while getting used
                    to your new dentures. Start with soft and liquid foods to
                    get accustomed to them. Similarly, you may struggle to speak
                    as you did before getting dentures. This gets easier as you
                    get used to your dentures. Practice saying words that are
                    difficult to pronounce to speed up the process.
                  </p>
                  <br />
                  <p>
                    Dentures can sometimes cause irritation or sores in the
                    mouth while you are getting used to them. This is common and
                    often subsides as you adjust. Gargle with salt water and
                    maintain excellent oral hygiene to help alleviate these
                    symptoms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-video-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>
                    Come to your dental clinic for adjustments to your dentures
                    if you experience the following:
                  </h2>
                  <ul>
                    <li>
                      Difficulty chewing after the adjustment period (which can
                      take about a week)
                    </li>
                    <li>
                      The fit changes over time, where it is not as snug as it
                      used to be (removable dentures may slip or fall out more
                      often, which is normal after several years but will
                      require refitting)
                    </li>
                    <li>
                      Pressure sores from where the false teeth are fit into
                      place, especially after the adjustment period
                    </li>
                    <li>
                      Consistent speech pattern changes that do not go away
                      after the adjustment period
                    </li>
                    <li>Odor coming from the false teeth</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
