import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import Appointment from "../Appointment";
import Banner from "../Banner";
import { compose } from "redux";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import getLanguageData from "../Helpers/getLanguageData";
import ServiceData from "../Data/ServiceData";
import Cleaning from "../Service/ServiceComponents/Cleaning";
import EmergencyDentistry from "../Service/ServiceComponents/EmergencyDentistry";
import Extractions from "../Service/ServiceComponents/Extractions";
import Orthodontics from "../Service/ServiceComponents/Orthodontics";
import Whitening from "../Service/ServiceComponents/Whitening";
import Implants from "../Service/ServiceComponents/Implants";
import Fillings from "../Service/ServiceComponents/Fillings";
import Dentures from "../Service/ServiceComponents/Dentures";
import Crowns from "../Service/ServiceComponents/Crowns";
import RootCanal from "../Service/ServiceComponents/RootCanal";
import NightGuards from "../Service/ServiceComponents/NightGuards";
import SleepApnea from "../Service/ServiceComponents/SleepApnea";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

class ServiceDetails extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies, match } = props;
    const id = +match.params.serviceId;
    let redirect = false;
    let inList = false;

    if (id) {
      ServiceData.serviceItems_Eng.forEach((item) => {
        if (item.id === id) {
          inList = true;
        }
      });
    }

    if (!id || !inList) {
      redirect = true;
    }
    this.state = {
      id: id,
      redirect: redirect,
      language: cookies.get("language") || "eng",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        language: language.language,
      };
    }

    return null;
  }

  renderServiceDetails = (id, language) => {
    let serviceID = +id;
    switch (serviceID) {
      case 1:
        return <Cleaning language={language} />;
      case 2:
        return <EmergencyDentistry language={language} />;
      case 3:
        return <Extractions language={language} />;
      case 4:
        return <Orthodontics language={language} />;
      case 5:
        return <Whitening language={language} />;
      case 6:
        return <Implants language={language} />;
      case 7:
        return <Fillings language={language} />;
      case 8:
        return <Dentures language={language} />;
      case 9:
        return <Crowns language={language} />;
      case 10:
        return <RootCanal language={language} />;
      case 11:
        return <NightGuards language={language} />;
      case 12:
        return <SleepApnea language={language} />;
      default:
        return <Redirect to="/404" />;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/404" />;
    }
    const serviceData = getLanguageData(
      this.state.language,
      ServiceData.serviceItems_Eng,
      ServiceData.serviceItems_Esp
    );
    const serviceItem = serviceData.filter(
      (item) => item.id === this.state.id
    )[0];
    return (
      <React.Fragment>
        <Banner
          pageTitle={serviceItem.title}
          BreadCrumb={this.state.language === "esp" ? "Servicios" : "Services"}
          BreadCrumbEng="Services"
          language={this.state.language}
        />
        {this.renderServiceDetails(this.state.id, this.state.language)}
        <Appointment
          title={
            this.state.language === "esp"
              ? "Solicitar Cita"
              : "Request Appointment"
          }
          language={this.state.language}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withCookies,
  withRouter
)(connect(mapStateToProps)(ServiceDetails));
