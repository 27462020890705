import React, { Component } from "react";
import Appointment from "../Appointment";
import Banner from "../Banner";
import { withRouter, Redirect } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { compose } from "redux";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import TeamData from "../Data/TeamData";
import getLanguageData from "../Helpers/getLanguageData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

class DentistDetails extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies, match } = props;
    const id = +match.params.dentistId;
    let redirect = false;
    let inList = false;

    if (id) {
      TeamData.forEach((item) => {
        if (item.id === Number(id)) {
          inList = true;
        }
      });
    }

    if (!id || !inList) {
      redirect = true;
    }
    this.state = {
      id: id,
      redirect: redirect,
      language: cookies.get("language") || "eng",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        language: language.language,
      };
    }

    return null;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/404" />;
    }
    const teamMember = TeamData.filter(
      (member) => member.id === this.state.id
    )[0];
    const description = getLanguageData(
      this.state.language,
      teamMember.description_eng,
      teamMember.description_esp
    );

    return (
      <React.Fragment>
        <Banner
          pageTitle={`Dr. ${teamMember.name}`}
          BreadCrumb={this.state.language === "esp" ? "Equipo" : "Team"}
          BreadCrumbEng="Team"
          language={this.state.language}
        />

        <section className="dentist-details-wrap section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-8 col-12">
                <div className="single-dentist-details">
                  <h2>
                    {`Dr. ${teamMember.name}`}{" "}
                    <span>{`(${teamMember.position})`}</span>
                  </h2>
                  {description.map((description, index) => {
                    return <p key={index}>{description}</p>;
                  })}
                </div>
              </div>
              <div className="col-md-5 col-lg-4 col-12 order-first order-md-last">
                <div className="dentist-profile-details">
                  <div className="doctor-profile">
                    <img
                      src={require("../../assets/img/team/" +
                        teamMember.imgUrl)}
                      alt=""
                    />
                  </div>
                  <p>
                    {this.state.language === "esp" ? "Nombre" : "Name:"}{" "}
                    <strong>{`Dr. ${teamMember.name}`}</strong>
                  </p>
                  <p>
                    <strong>{teamMember.position}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Appointment
          title={
            this.state.language === "esp"
              ? "Solicitar Cita"
              : "Request Appointment"
          }
          language={this.state.language}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withCookies,
  withRouter
)(connect(mapStateToProps)(DentistDetails));
