import React from "react";

export default function Location(props) {
  return (
    <div
      id="location"
      className="row align-items-center justify-content-center"
    >
      <iframe
        title="Dental Access Texas"
        width={props.width}
        height={props.height}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=4320%20S.%20State%20Highway%20360%20Suite%20500%20Grand%20Prairie+(Dental%20Access%20-%20Grand%20Prairie)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=f9d34c730d8fbe0bbf7b351381b33f10881c19b7"
      ></script>
    </div>
  );
}
