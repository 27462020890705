const BeforeAfterData = [
  {
    id: 1,
    before: '01_before.jpg',
    after: '01_after.jpg',
  },
  {
    id: 2,
    before: '02_before.jpg',
    after: '02_after.jpg',
  },
  {
    id: 3,
    before: '03_before.jpeg',
    after: '03_after.png',
  },
  {
    id: 4,
    before: '04_before.jpg',
    after: '04_after.jpg',
  },
  {
    id: 5,
    before: '05_before.jpg',
    after: '05_after.jpg',
  },
  {
    id: 6,
    before: '06_before.jpg',
    after: '06_after.jpg',
  },
  {
    id: 7,
    before: '07_before.jpg',
    after: '07_after.jpg',
  },
  {
    id: 8,
    before: '08_before.jpg',
    after: '08_after.jpg',
  },
  {
    id: 9,
    before: '09_before.jpg',
    after: '09_after.jpg',
  },
  {
    id: 10,
    before: '10_before.jpg',
    after: '10_after.jpg',
  },
  {
    id: 11,
    before: '11_before.jpg',
    after: '11_after.jpg',
  },
  {
    id: 12,
    before: '12_before.jpg',
    after: '12_after.jpg',
  },
  {
    id: 13,
    before: '13_before.jpg',
    after: '13_after.jpg',
  },
  {
    id: 16,
    before: '16_before.jpg',
    after: '16_after.jpg',
  },
  {
    id: 17,
    before: '17_before.jpg',
    after: '17_after.jpg',
  },
  {
    id: 18,
    before: '18_before.jpg',
    after: '18_after.jpg',
  },
  {
    id: 19,
    before: '19_before.jpg',
    after: '19_after.jpg',
  },
  {
    id: 20,
    before: '20_before.jpg',
    after: '20_after.jpg',
  },
  {
    id: 21,
    before: '21_before.jpg',
    after: '21_after.jpg',
  },
  {
    id: 22,
    before: '22_before.jpg',
    after: '22_after.jpg',
  },
];

export default BeforeAfterData;
