import React, { Component } from "react";
import Banner from "../Banner";
import SectionTitleOne from "../SectionTitleOne";
import Appointment from "../Appointment";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { AboutPage } from "../Data/PageData";
import { connect } from "react-redux";
import getLanguageData from "../Helpers/getLanguageData";
import TeamOne from "../Team/TeamOne";
import TeamData from "../Data/TeamData";
import Location from "../location";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

class About extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      language: cookies.get("language") || "eng",
      pageData: getLanguageData(
        cookies.get("language"),
        AboutPage.eng,
        AboutPage.esp
      ),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        pageData: getLanguageData(
          language.language,
          AboutPage.eng,
          AboutPage.esp
        ),
        language: language.language,
      };
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        <Banner
          pageTitle={
            this.state.language === "esp" ? "Sobre Nosotros" : "About Us"
          }
          language={this.state.language}
        />

        <section className="about-page-top-content-wrap section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                <div className="section-text-wrap">
                  <h1>{this.state.pageData.mission.header}</h1>
                  <p style={{ fontSize: "20px" }}>
                    {this.state.pageData.mission.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="patient-focused-wrap section-padding text-white section-bg text-center">
          <div className="container">
            <SectionTitleOne
              BigTitle={
                this.state.language === "esp"
                  ? "Enfoque Centrado en el Paciente"
                  : "Patient Focused Approach"
              }
            />

            <div className="row">
              <div className="col-md-6 col-lg-4 col-12">
                <div className="best-service-item-box">
                  <div className="service-box-icon">
                    <img
                      src={require("../../assets/img/icons/love.png")}
                      alt="heart"
                    />
                  </div>
                  <h3>
                    {this.state.language === "esp"
                      ? "Transparencia"
                      : "Transparency"}
                  </h3>
                  <p>
                    {this.state.language === "esp"
                      ? "Nos aseguramos de que todo está claro y transparente para usted"
                      : "We ensure that everything is clear and transparent for you"}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <div className="best-service-item-box">
                  <div className="service-box-icon">
                    <img
                      src={require("../../assets/img/icons/like.png")}
                      alt="heart text"
                    />
                  </div>
                  <h3>
                    {this.state.language === "esp"
                      ? "Familiaridad"
                      : "Familiarity"}
                  </h3>
                  <p>
                    {this.state.language === "esp"
                      ? "Nos aseguramos de que se sienta cómodo y confiado con nosotros"
                      : "We ensure you are comfortable and confident with us"}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <div className="best-service-item-box">
                  <div className="service-box-icon">
                    <img
                      src={require("../../assets/img/icons/respect.png")}
                      alt="handshake"
                    />
                  </div>
                  <h3>
                    {this.state.language === "esp" ? "Resultado" : "Outcome"}
                  </h3>
                  <p>
                    {this.state.language === "esp"
                      ? "Nos aseguramos de que salgas con la mayor sonrisa"
                      : "We ensure you walk out with the biggest smile"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="promo-content-section promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center justify-content-center mb-5">
              <h1 className="text-center">
                {this.state.language === "esp"
                  ? "Nuestra Ubicación"
                  : "Our Location"}
              </h1>
            </div>
            <Location width="800" height="500" />
          </div>
        </section>

        <TeamOne TeamData={TeamData} language={this.state.language} />
        <Appointment
          title={
            this.state.language === "esp"
              ? "Solicitar Cita"
              : "Request Appointment"
          }
          language={this.state.language}
        />
      </React.Fragment>
    );
  }
}

export default withCookies(connect(mapStateToProps)(About));
