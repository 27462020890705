import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import { BUSINESS_DATA_02 as BUSINESS_DATA } from "../Data/BusinessData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faMailBulk,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import getLanguageData from "../Helpers/getLanguageData";
import Location from "../location";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export class FooterOne extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      language: cookies.get("language") || "eng",
      businessData: BUSINESS_DATA,
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        language: language.language,
      };
    }

    return null;
  }
  render() {
    let FooterData = this.props.FooterData;
    let singleWidget = getLanguageData(
      this.state.language,
      FooterData.singleWidget_eng,
      FooterData.singleWidget_esp
    );
    return (
      <footer className="footer-wrapper footer-one">
        <div className="footer-widgets-wrapper section-bg text-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-6 col-lg-3 offset-lg-1 col-12">
                <div className="single-footer-widget">
                  <div className="f-widget-title">
                    <h2>
                      {this.state.language === "esp"
                        ? "Nuestra Dirección"
                        : "Our Address"}
                    </h2>
                  </div>
                  <div className="widegts-content">
                    <li>
                      <a
                        style={{ fontSize: 15 }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`http://maps.google.com/?q=${this.state.businessData.addressStreet} ${this.state.businessData.addressCity}, ${this.state.businessData.addressState} ${this.state.businessData.addressZipCode}`}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 5 }}
                          icon={faBuilding}
                        />
                        {this.state.businessData.addressStreet} <br />{" "}
                        {`${this.state.businessData.addressCity}, ${this.state.businessData.addressState} ${this.state.businessData.addressZipCode}`}
                      </a>
                    </li>

                    <li>
                      <a href={`tel:+1 ${this.state.businessData.phone}`}>
                        <FontAwesomeIcon
                          style={{ marginRight: 5 }}
                          icon={faPhone}
                        />
                        {this.state.businessData.phone}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${this.state.businessData.email}`}>
                        <FontAwesomeIcon
                          style={{ marginRight: 5 }}
                          icon={faMailBulk}
                        />
                        {this.state.businessData.email}
                      </a>
                    </li>
                    <li style={{ marginTop: 10 }}>
                      <ul>
                        <li style={{ display: "inline-block" }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={this.state.businessData.instagram}
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              size="lg"
                              icon={faInstagram}
                            />
                          </a>
                        </li>
                        <li style={{ display: "inline-block" }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={this.state.businessData.facebook}
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              size="lg"
                              icon={faFacebook}
                            />
                          </a>
                        </li>
                        {/* <li style={{ display: "inline-block" }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://twitter.com/"
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              size="lg"
                              icon={faTwitter}
                            />
                          </a>
                        </li> */}
                        <li style={{ display: "inline-block" }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={this.state.businessData.google}
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              style={{ marginRight: 30 }}
                              size="lg"
                              icon={faGoogle}
                            />
                          </a>
                        </li>
                      </ul>
                    </li>
                  </div>
                </div>
              </div>
              <div className="col col-sm-6 ml-sm-3">
                <Location width="500" height="250" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright-wrapper text-center">
          <p>&copy; {FooterData.copyright}</p>
        </div>
      </footer>
    );
  }
}

export default withCookies(connect(mapStateToProps)(FooterOne));
