import React, { Component } from "react";
import Navbar from "./Navbar";
import { changeLanguage } from "../../redux/ActionCreators";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = {
  changeLanguage: (language) => changeLanguage(language),
};

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar changeLanguage={this.props.changeLanguage} />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
