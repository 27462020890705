import React from "react";

export default function Extractions(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Extracciones Simples y Extracciones Quirúrgicas</h2>
                  <p>
                    <b>La extracción simple</b> implica anestesia local, que
                    adormece el área alrededor de su diente para que sienta solo
                    presión, no dolor, durante el procedimiento. Luego, el
                    dentista usa un instrumento llamado elevador para aflojar el
                    diente y unas pinzas para extraerlo.
                  </p>
                  <p>
                    <b>La extracción quirúrgica </b>implica un procedimiento de
                    extracción más invasivo. El dentista general o el cirujano
                    oral cortará la encía con una pequeña incisión. Es posible
                    que necesiten extraer el hueso alrededor de su diente o
                    cortarlo antes de poder extraerlo. A veces, se necesitan
                    suturas y paquetes medicados para asegurar el sitio de
                    extracción.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/femalePatient.png")}
                  alt="Female Patient"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="promo-content-section section-bg text-white promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mb-4 mb-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/extractionPatient.png")}
                  alt="Extraction Patient"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Qué Esperar Después De La Extracción Del Diente</h2>
                  <p>
                    Normalmente, la recuperación después de la extracción de un
                    diente demora unos días. Los siguientes pasos ayudan a
                    garantizar que su recuperación se desarrolle sin problemas:
                  </p>
                  <ul>
                    <li>
                      Aplique una compresa de hielo en su mejilla directamente
                      después del procedimiento para reducir la hinchazón. Use
                      la bolsa de hielo durante 10 minutos cada vez.
                    </li>
                    <li>
                      Después de que el dentista coloque la gasa sobre el área
                      afectada, muerda para reducir el sangrado y ayudar a la
                      formación de coágulos. Deje la gasa puesta de tres a
                      cuatro horas o hasta que la gasa esté empapada de sangre.
                    </li>
                    <li>
                      Tome cualquier medicamento según lo prescrito, incluidos
                      los analgésicos de venta libre.
                    </li>
                    <li>
                      Descanse y relájese durante las primeras 24 horas. No
                      entre inmediatamente a su rutina habitual al día
                      siguiente.
                    </li>
                    <li>No use una pajita durante las primeras 24 horas.</li>
                    <li>No fume.</li>
                    <li>
                      No enjuague durante 24 horas después de la extracción del
                      diente, y escupe solo suavemente.
                    </li>
                    <li>
                      Use almohadas para levantar la cabeza cuando se acueste.
                    </li>
                    <li>
                      Cepíllese los dientes y use hilo dental como de costumbre,
                      pero evite el lugar de la extracción.
                    </li>
                    <li>
                      El día después del procedimiento, coma alimentos blandos,
                      como yogur, pudín y puré de manzana.
                    </li>
                    <li>
                      A medida que se recupere durante los próximos días, puede
                      reintroducir lentamente otros alimentos en su dieta.
                    </li>
                  </ul>
                  <p>
                    Si siente dolor que no desaparece después de varios días o
                    signos de una infección, como fiebre, dolor y pus o
                    secreción de la incisión, programe una cita para ver a su
                    dentista lo antes posible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-video-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Complicaciones De La Extracción De Dientes.</h2>
                  <p>
                    Por lo general, después de la extracción de un diente, se
                    forma un coágulo de sangre de forma natural en la cavidad,
                    el orificio en el hueso donde se extrajo el diente. Sin
                    embargo, si el coágulo de sangre no se forma o se desprende,
                    el hueso dentro de la cavidad puede quedar expuesto, lo que
                    se denomina "alveolitis seca". Estas complicaciones también
                    pueden ocurrir si el paciente mastica la comida y la comida
                    se desprende en el sitio de extracción y se forma una
                    infección. Si esto sucede, vaya a ver a su dentista para
                    remediar la alveolitis seca. El dentista protegerá el área
                    colocando un apósito sedante durante unos días. Durante este
                    tiempo, se formará un nuevo coágulo.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Simple Extractions & Surgical Extractions</h2>
                  <p>
                    <b>Simple extraction</b> involves local anesthetic, which
                    numbs the area around your tooth, so you will feel only
                    pressure, not pain, during the procedure. The dentist then
                    uses an instrument called an elevator to loosen the tooth
                    and forceps to remove it.
                  </p>
                  <p>
                    <b>Surgical extraction</b> involves a more invasive
                    extraction procedure. The general dentist or oral surgeon
                    will cut into your gum with a small incision. They may need
                    to remove bone around your tooth or cut your tooth before it
                    can be extracted. Sometimes suturing and medicated packs are
                    needed to secure the extraction site.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/femalePatient.png")}
                  alt="Female Patient"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="promo-content-section section-bg text-white promo-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mb-4 mb-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/extractionPatient.png")}
                  alt="Extraction Patient"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>What To Expect After Tooth Extraction</h2>
                  <p>
                    It normally takes a few days to recover after a tooth
                    extraction. The following steps help ensure that your
                    recovery goes smoothly:
                  </p>
                  <ul>
                    <li>
                      Apply an ice pack to your cheek directly after the
                      procedure to reduce swelling. Use the ice pack for 10
                      minutes each time.
                    </li>
                    <li>
                      After the dentist places the gauze pad over the affected
                      area, bite down to reduce bleeding and to aid in clot
                      formation. Leave the gauze on for three to four hours, or
                      until the pad is soaked with blood.
                    </li>
                    <li>
                      Take any medications as prescribed, including
                      over-the-counter painkillers.
                    </li>
                    <li>
                      Rest and relax for the first 24 hours. Do not jump
                      immediately into your regular routine the following day.
                    </li>
                    <li>Do not use a straw for the first 24 hours.</li>
                    <li>Do not smoke.</li>
                    <li>
                      Do not rinse for 24 hours after the tooth extraction, and
                      spit only gently.
                    </li>
                    <li>Use pillows to prop your head up when you lie down.</li>
                    <li>
                      Brush and floss your teeth like normal but avoid the
                      extraction site.
                    </li>
                    <li>
                      The day after the procedure, eat soft foods, such as
                      yogurt, pudding, and applesauce.
                    </li>
                    <li>
                      As you heal over the next few days, you can slowly
                      reintroduce other foods into your diet.
                    </li>
                  </ul>
                  <p>
                    If you are experiencing pain that is not going away after
                    several days or signs of an infection —including fever,
                    pain, and pus or drainage from the incision — make an
                    appointment to see your dentist as soon as possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-video-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>Tooth Extraction Complications</h2>
                  <p>
                    Usually after a tooth extraction, a blood clot naturally
                    forms in the socket — the hole in the bone where the tooth
                    has been extracted. However, if the blood clot does not form
                    or dislodges, the bone inside the socket can be exposed —
                    referred to as “dry socket.” These complications can also
                    occur if patient chewed food and the food gets dislodge in
                    the extraction site and an infection form. If this happens,
                    go in, and see your dentist to remedy the dry socket. The
                    dentist will protect the area by putting a sedative dressing
                    over it for a few days. During this time, a new clot will
                    form.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
